import { createSlice } from '@reduxjs/toolkit';

const ugcSlice = createSlice({
  name: 'ugcSlice',
  initialState: {
    data: null,
    status: 'idle',
  },
  reducers: {
    setUgcData: (state, action) => {
      state.data = action.payload;
    },
    clearUgcData: (state) => {
      state.data = null;
    },
  },
});

export const { setUgcData, clearUgcData } = ugcSlice.actions;
export default ugcSlice.reducer;
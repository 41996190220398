import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from "react-router-dom";
import { lazy, Suspense } from "react";
import FullScreenLoader from "../components/loader";
import useAuth from "../utils/hooks/use-auth";
import { useLocation } from "react-router-dom";
// import Reels from '../pages/Reels';
import {
  addPathSeparators,
  isOldKaraoke,
  manageKaraokePath,
} from "../utils/helpers";
import { useSelector} from "react-redux";

// import { setAfterLoginAction } from '../store/slices/common-slice';

// Lazy load components with React.lazy
const HomePage = lazy(() => import("../pages/Home"));
const App = lazy(() => import("../App"));
const Page404 = lazy(() => import("../pages/404"));
const ConnectionLoss = lazy(() => import("../pages/ConnectionLoss"));
const Login = lazy(() => import("../features/auth/Login"));
const SignUp = lazy(() => import("../features/auth/SignUp"));
const OtpVerification = lazy(() => import("../features/auth/OtpVerification"));
const MyProfile = lazy(() => import("../pages/MyProfile"));
const MyWallet = lazy(() => import("../pages/Wallet"));
const Notification = lazy(() => import("../pages/Notification"));
const Faq = lazy(() => import("../pages/Faq"));
const Contest = lazy(() => import("../pages/Contest"));
const Info = lazy(() => import("../pages/Info"));
const TermsCondition = lazy(() => import("../pages/TermsConditions"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));
const AuthAccess = lazy(() => import("../pages/AuthAccess"));
const Artist = lazy(() => import("../pages/Artist"));
const ArtistDetails = lazy(() => import("../pages/ArtistDetail"));
const SeeAll = lazy(() => import("../pages/SeeAllVedio"));
const Sitemap = lazy(() => import("../pages/Sitemap"));
const ClaimForm = lazy(() => import("../components/common/appForm/ClaimForm"));
const Referral = lazy(() => import("../pages/ReferaFriend"));
const SendReminder = lazy(() => import("../pages/SendReminder"));
const SiteMap = lazy(() => import("../pages/Sitemap"));
const Popup = lazy(() => import("../pages/popup"));
const SongDetail = lazy(() => import("../pages/SongDetail"));
const UGCVedios = lazy(() => import("../pages/UgcVedios"));
const Reels = lazy(() => import("../pages/Reels"));
const WinnerList = lazy(() => import("../pages/WinnerList"));
const ReelSection = lazy(() => import("../pages/reelpage"));
// Karaoke components
const KaraokesongList = lazy(() => import("../pages/karaoke2.0/songList"));
const KaraokeRecording = lazy(() =>
  import("../pages/karaoke2.0/KaraokeRecording")
);
const RecordPreview = lazy(() => import("../pages/karaoke2.0/RecordPreview"));

const loaderComponent = (
  <div>
    <FullScreenLoader />
  </div>
);

// Create a wrapper component for lazy loaded components
function LazyRoute({ component: Component }) {
  return (
    <Suspense fallback={loaderComponent}>
      <Component />
    </Suspense>
  );
}

function ProtectedRoute({ component: Component }) {
  const { isLoggedIn } = useAuth();
  if (!isLoggedIn) {
    // Redirect to login if not authenticated
    //window.location.assign('/login')
    localStorage.setItem("lastRoute",window.location.pathname)
    return <Navigate to={addPathSeparators("/login")} />;
  }
  return <Component />;
}

// const extractIdFromRoute = (route) => {
//   const matches = route.match(/\/([^\/]+)/);
//   return matches ? matches[1] : null;
// };

function PublicOnlyRoute({ component: Component }) {
  const { isLoggedIn } = useAuth();
  const { state, pathname } = useLocation();
  // const dispatch = useDispatch();

  const afterLoginParams = useSelector(
    (state) => state?.common?.afterLoginAction
  );
  if (isLoggedIn) {
    if (afterLoginParams && afterLoginParams.redirectPath) {
      const redirectPath = afterLoginParams.redirectPath;
      // dispatch(setAfterLoginAction({
      //   redirectPath: ''
      // }));
      if (redirectPath === manageKaraokePath()) {
        window.location.assign(manageKaraokePath());
      } else {
        return (
          <Navigate to={addPathSeparators(afterLoginParams.redirectPath)} />
        );
      }
    } else if (afterLoginParams && afterLoginParams.action) {
      afterLoginParams.action();
    }
  }
  if (
    (pathname === addPathSeparators("/signup") ||
      pathname === addPathSeparators("/otp_verification")) &&
    !(state && state.mobile)
  ) {
    return <Navigate to={addPathSeparators("/login")} />;
  }

  return <Component />;
}

const renderRoute = ({ element, ...props }) => {
  // Check if the route requires authentication
  if (props.requiresAuth) {
    return <ProtectedRoute component={() => element} />;
  }
  return <LazyRoute component={() => element} />;
};
const renderSiteSepratorRoute = ({ element }) => {
  // Check if the route requires authentication
  if(["/bharat","/tamil"].includes(window.location.pathname))
    {
      return <LazyRoute component={() => element} />;
    }else{
      return <Navigate to="/" />;
    }
};
export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path={"/"}
      element={<LazyRoute component={App} />}
      errorElement={<LazyRoute component={Page404} />}
    >
      {/* without login  */}
      <Route path={"/"} element={<LazyRoute component={HomePage} />} />
      {/* <Route path={`${restricThePath}`} element={<LazyRoute component={HomePage} />} /> */}
      {/* <Route path={addPathSeparators("/")} element={<LazyRoute component={HomePage} />} /> */}
      <Route path={"/:siteType"} element={renderSiteSepratorRoute({element:<HomePage />})} />
      <Route
        path={addPathSeparators("/connection_loss")}
        element={<LazyRoute component={ConnectionLoss} />}
      />
      <Route
        path={addPathSeparators("/login")}
        element={
          <PublicOnlyRoute component={() => <LazyRoute component={Login} />} />
        }
      />
      <Route
        path={addPathSeparators("/signup")}
        element={
          <PublicOnlyRoute component={() => <LazyRoute component={SignUp} />} />
        }
      />
      <Route
        path={addPathSeparators("/auth_access/:key")}
        element={
          <PublicOnlyRoute
            component={() => <LazyRoute component={AuthAccess} />}
          />
        }
      />
      <Route
        path={addPathSeparators("/otp_verification")}
        element={
          <PublicOnlyRoute
            component={() => <LazyRoute component={OtpVerification} />}
          />
        }
      />
      {/* without login  and with login both can access */}
      <Route
        path={addPathSeparators("/notifications")}
        element={<LazyRoute component={Notification} />}
      />
      <Route
        path={addPathSeparators("/faq")}
        element={<LazyRoute component={Faq} />}
      />
      <Route
        path={addPathSeparators("/sitemap")}
        element={<LazyRoute component={Sitemap} />}
      />
      <Route
        path={addPathSeparators("/terms_conditions")}
        element={<LazyRoute component={TermsCondition} />}
      />
      <Route
        path={"/terms_conditions"}
        element={<LazyRoute component={TermsCondition} />}
      />
      <Route
        path={addPathSeparators("/privacy_policy")}
        element={<LazyRoute component={PrivacyPolicy} />}
      />
      <Route
        path={addPathSeparators("/privacy_policy")}
        element={<LazyRoute component={PrivacyPolicy} />}
      />
      <Route
        path={addPathSeparators("/popup")}
        element={<LazyRoute component={Popup} />}
      />
      <Route
        path={addPathSeparators("/song_detail/:id")}
        element={<LazyRoute component={SongDetail} />}
      />
      <Route
        path={addPathSeparators("/see_all/:cardtype/:section")}
        element={<LazyRoute component={SeeAll} />}
      />
            <Route
        path={addPathSeparators("/contest")}
        element={<LazyRoute component={Contest} />}
      />
      <Route
        path={addPathSeparators("/ugc/:cardtype/:section")}
        element={<LazyRoute component={UGCVedios} />}
      />
      <Route
        path={addPathSeparators("/artist")}
        element={<LazyRoute component={Artist} />}
      />

      <Route
        path={addPathSeparators("/artist_details/:artistId")}
        element={<LazyRoute component={ArtistDetails} />}
      />
      <Route
        path={addPathSeparators("/site_map")}
        element={<LazyRoute component={SiteMap} />}
      />
      {/* <Route
        path={addPathSeparators("/reels/:reel_id")}
        element={<LazyRoute component={Reels} />}
      /> */}
       <Route
        path={addPathSeparators("/reels/:reel_id")}
        element={<LazyRoute component={ReelSection} />}
      />

      <Route path="/faq" element={<LazyRoute component={Faq} />} />
      <Route path="/sitemap" element={<LazyRoute component={Sitemap} />} />

      {/* ugc changes */}
       {/* <Route path="/reelsection" element={<LazyRoute component={ReelSection} />} /> */}

      {/* with login  */}

      {/* Karaoke 2.0  */}
      {/* applied checks to check the karoke version */}
      {isOldKaraoke && isOldKaraoke === "false" && (
        <>
          <Route
            path={addPathSeparators("/KaraokesongList")}
            element={renderRoute({
              element: <KaraokesongList />,
              requiresAuth: true
            })}
          />
          <Route
            path={addPathSeparators("/karaoke_recording/:recordType")}
            element={renderRoute({
              element: <KaraokeRecording />,
              requiresAuth: true
            })}
          />
          <Route
            path={addPathSeparators("/videoResult/:recordType")}
            element={renderRoute({
              element: <RecordPreview />,
              requiresAuth: true
            })}
          />
        </>
      )}

      <Route
        path={addPathSeparators("/my_profile")}
        element={renderRoute({ element: <MyProfile />, requiresAuth: true })}
      />
      <Route
        path={addPathSeparators("/my_profile/:id")}
        element={renderRoute({ element: <MyProfile />, requiresAuth: true})}
      />
      <Route
        path={addPathSeparators("/my_wallet")}
        element={renderRoute({ element: <MyWallet />, requiresAuth: true })}
      />
      <Route
        path={addPathSeparators("/claim_form/:winnerRewardid")}
        element={renderRoute({ element: <ClaimForm />, requiresAuth: true})}
      />

      <Route
        path={addPathSeparators("/referral")}
        element={renderRoute({ element: <Referral />, requiresAuth: true})}
      />
      <Route
        path={addPathSeparators("/send-reminder")}
        element={renderRoute({ element: <SendReminder />, requiresAuth: true})}
      />
      <Route
        path={addPathSeparators("/info")}
        element={renderRoute({ element: <Info />, requiresAuth: false })}
      />
      <Route
        //if applicable for both bharat/tamil then addPathSeparators will use in path name
        path={addPathSeparators('/winner-list')}
        // path={"tamil/winner-list"}
        element={renderRoute({ element: <WinnerList />, requiresAuth: true})}
      />

      {/* <Route
        path={addPathSeparators('/winner-list')}
        element={renderRoute({ element: <Contest />, requiresAuth: true })}
      /> */}
    </Route>
  )
);

import { combineReducers } from "redux";

import auth from "./auth-slice";
import common from "./common-slice";
import karaoke from "./karaokeRecord-slice";
import ugcHome from "./ugchome";
import ugcSlice from './ugcSlice';
import filterUGC from './filter-ugc-slice'

export default combineReducers({
  auth,
  common,
  karaoke,
  ugcHome,
  ugcSlice,
  filterUGC,
});

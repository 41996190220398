import { createSlice } from '@reduxjs/toolkit';

const filterUGCSlice = createSlice({
    name: 'filterUGC',
    initialState: {
        isFilterOn: false,
        sortValue: 'newest',
        searchfield: '',
        pageno: 0,
        reduxhasmore: true,
        filteredData : [],
    },
    reducers: {
        setFilterON: (state, action) => {
            state.isFilterOn = action.payload;
        },
        setFilterValue: (state, action) => {
            const { sortValue, searchfield, pageno, reduxhasmore } = action.payload;
            if (sortValue !== undefined) state.sortValue = sortValue;
            if (searchfield !== undefined) state.searchfield = searchfield;
            if (pageno !== undefined) state.pageno = pageno;
            if (reduxhasmore !== undefined) state.reduxhasmore = reduxhasmore;
        },
        setFilteredData: (state, action) => {
            state.filteredData = action.payload; 
        }
    }
});

export const { setFilterON, setFilterValue ,setFilteredData} = filterUGCSlice.actions;
export default filterUGCSlice.reducer;

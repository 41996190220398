import { createSlice } from '@reduxjs/toolkit';



const ugcSlice = createSlice({
    name: 'ugcHome',
    initialState: {
      videoId: null,
      views: 0,
      likes: 0,
      likedVideos: [], // List of liked video IDs
    },
    reducers: {
      setUgcHome: (state, action) => {
        const { videoId, views, likes } = action.payload;
        state.videoId = videoId;
        state.views = views;
        state.likes = likes;
      },
      setUgcLikes: (state, action) => {
        const likedVideos = action.payload;
        state.likedVideos = likedVideos; // Updating liked videos
      },
    },
  });
  
  // Export actions and reducer
  export const { setUgcHome, setUgcLikes } = ugcSlice.actions;
  export default ugcSlice.reducer;
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import usePopup from "../../utils/hooks/use-popup";
import useAuth from "../../utils/hooks/use-auth";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "../../utils/hooks/use-translation";
import { useViewport } from "../../utils/context/viewport-context";
import { useNavigate } from "react-router-dom";
import { setSiteType } from "../../store/slices/common-slice";
import {
  addPathSeparators,
  CounterFloodLight,
  isBharatCampaignEnded,
  isTamil,
  isTamilCampaignEnded,
} from "../../utils/helpers";
import { ClickCDP } from "../../utils/cds/cdsHelper";
import { setAfterLoginAction } from "../../store/slices/common-slice";
import { getModelType } from "../../utils/constants";
import SuccessModel from '../modals/api_response_model';

function Footer() {
  const siteType = useSelector((state) =>
    state?.common?.siteType && state?.common?.siteType.siteType
      ? state?.common?.siteType.siteType
      : "bharat"
  );

  const dispatch = useDispatch();
  var isCampaignOver = siteType === "tamil"? isTamilCampaignEnded(): isBharatCampaignEnded();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openUniqueCodePopup, openContactUsPopup } = usePopup();
  const [showsuccessModel, setShowSuccessModel] = useState({
    show: false,
    modelType: '',
  });
  const { isLoggedIn, userDetails } = useAuth();
  const homePageData = useSelector((state) => state?.common?.homepageDetails);
  const { pathname } = useLocation();
  const { isMobile } = useViewport();
  const footer_type = pathname.startsWith("/claim_form") && isMobile;
  const linkClassname =
    "d-flex justify-content-center align-items-center flex-column";
  const uniquecodeQuotaFinish =
    userDetails && userDetails?.uniquecodeQuotaFinish;
  // let otherLogo =
  //   siteType === 'tamil'
  //     ? 'bharat'
  //     : siteType === 'bharat'
  //       ? 'tamil'
  //       : siteType === 'bangla'
  //         ? 'bharat'P
  //         : 'bharat';
  const contentType = getModelType(t, 12);
  return (
    <>
      {!footer_type && !(pathname.includes("karaoke_recording/Video") || pathname.includes("reels")) && (
        <footer className="bg-dark footer">
          <div className="container-fluid">
            <div className="d-block d-lg-none">
              <div className="row">
                <div className="col home text-left">
                  <Link
                    to={addPathSeparators("/")}
                    className={`link ${linkClassname} ${
                      pathname === addPathSeparators("/") || pathname === "/"
                        ? "active"
                        : ""
                    }`}
                  >
                    <img
                      src="/assets/img/footer/home-icon.svg"
                      alt=" Logo"
                      className="footer-icon"
                    />
                    <span>{t.header.home}</span>
                  </Link>
                </div>
                <div className="col contest">
                  {isCampaignOver ? (
                    <Link
                      to={addPathSeparators("/info")}
                      className={`link ${linkClassname} ${
                        pathname === addPathSeparators("/info")
                          ? "active"
                          : ""
                      }`}
                      // onClick={() => {
                      //   if (!isLoggedIn) {
                      //     dispatch(
                      //       setAfterLoginAction({
                      //         action: "",
                      //         redirectPath: "/info",
                      //       })
                      //     );
                      //   }
                      // }}
                    >
                      <img
                        src={"/assets/img/footer/info-icon.svg"}
                        alt=" Logo"
                        className="footer-icon"
                      />
                      <span>{t.common.info}</span>
                    </Link>
                  ) : (
                    <Link
                      to={
                       addPathSeparators("/contest")
                       }
                      className={`link ${linkClassname} ${
                        pathname === addPathSeparators("/contest")
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        if (!isLoggedIn) {
                          dispatch(
                            setAfterLoginAction({
                              action: "",
                              redirectPath: "/contest",
                            })
                          );
                        }
                      }}
                    >
                      <img
                        src={"/assets/img/footer/contest-icon.svg"}
                        alt=" Logo"
                        className="footer-icon"
                      />
                      <span>{t.common.contest}</span>
                    </Link>
                  )}
                </div>

                <div
                  className={`col ${
                    uniquecodeQuotaFinish ? "unique_code" : ""
                  }`}
                >
                  <div
                    className="link d-flex justify-content-center align-items-center flex-column"
                    onClick={() => {
                      if (!uniquecodeQuotaFinish) {
                        CounterFloodLight([
                          "DC-12665261/coca-0/cocac02f+unique",
                          "DC-12665261/coca-0/cocac039+unique",
                        ]);
                      }else{
                        setShowSuccessModel({
                          show: true,
                          modelType: ''
                        })
                      }
                      if (!isLoggedIn) {
                        dispatch(
                          setAfterLoginAction({
                            action: () => openUniqueCodePopup(),
                            redirectPath: "",
                          })
                        );
                      }
                      isLoggedIn
                          ? openUniqueCodePopup()
                          : navigate(addPathSeparators("/login"));
                    }}
                  >
                    <img
                      src={uniquecodeQuotaFinish ?"/assets/img/footer/unique-code-disabled.svg":"/assets/img/footer/unique-code.svg"}
                      alt=" Logo"
                      className="footer-img unique-code"
                    />
                    {/* <span>{t.common.code}</span> */}
                  </div>
                </div>

                <div className="col wallet" id="my_wallet_animation">
                  <Link
                    to={
                      isLoggedIn
                        ? addPathSeparators("/my_wallet")
                        : addPathSeparators("/login")
                    }
                    onClick={() => {
                      if (!isLoggedIn) {
                        dispatch(
                          setAfterLoginAction({
                            action: "",
                            redirectPath: "/my_wallet",
                          })
                        );
                      }
                      CounterFloodLight([
                        "DC-12665261/coca-0/cocac028+unique",
                        "DC-12665261/coca-0/cocac031+unique",
                      ]);
                    }}
                    className={`link ${linkClassname} ${
                      pathname === addPathSeparators("/my_wallet")
                        ? "active"
                        : ""
                    }`}
                  >
                    <img
                      src="/assets/img/footer/wallet-icon.svg"
                      alt=" Logo"
                      className="footer-icon"
                    />
                    <small className="count">
                      {homePageData && homePageData.coinCount}
                    </small>
                    <span>{t.common.wallet}</span>
                  </Link>
                </div>
                <div className="col profile">
                  <Link
                    to={
                      isLoggedIn
                        ? addPathSeparators("/my_profile")
                        : addPathSeparators("/login")
                    }
                    onClick={() => {
                      if (!isLoggedIn) {
                        dispatch(
                          setAfterLoginAction({
                            action: "",
                            redirectPath: "/my_profile",
                          })
                        );
                      }
                      CounterFloodLight([
                        "DC-12665261/coca-0/cocac02a+unique",
                        "DC-12665261/coca-0/cocac034+unique",
                      ]);
                    }}
                    className={`link ${linkClassname} ${
                      pathname === addPathSeparators("/my_profile")
                        ? "active"
                        : ""
                    }`}
                  >
                    <img
                      src="/assets/img/footer/user-icon.svg"
                      alt=" Logo"
                      className="footer-icon"
                    />
                    <span>{t.common.user}</span>
                  </Link>
                </div>
              </div>
              <div className="row align-items-center mt-2">
                <div className="col-5 mr-0">
                  <Link
                    onClick={() => {
                      CounterFloodLight([
                        "DC-12665261/coca-0/cocac02e+unique",
                        "DC-12665261/coca-0/cocac038+unique",
                      ]);
                    }}
                    to={addPathSeparators("/terms_conditions")}
                    className="border-right pr-3 link link-hover-disable"
                  >
                    {t.common.tnc}
                    <sup>*</sup>
                  </Link>
                  <Link
                    onClick={() => {
                      CounterFloodLight([
                        "DC-12665261/coca-0/cocac02-+unique",
                        "DC-12665261/coca-0/cocac033+unique",
                      ]);
                    }}
                    to={addPathSeparators("/privacy_policy")}
                    className="pl-3 link link-hover-disable"
                  >
                    {t.header.privacy_policy}
                  </Link>
                </div>
                <div className="col-7 text-right">
                  <p className="mb-0 text-nowrap">
                    {t.common.footer_heading}{" "}
                    <img src="/assets/img/icons/dietary_symbol.svg" alt="" />
                  </p>
                </div>
              </div>
            </div>
            {/* desktop footer part  */}
            <div className="desk-footer d-lg-block d-none ">
              <div className="row">
                <div className="col-12">
                  <div className="border-bottom pb-7 mb-12">
                    <h1
                      title={isTamil() ? t.common.h1TTitle : t.common.h1BTitle}
                    >
                      <img
                        src={`/assets/img/icons/${siteType}-logo.svg`}
                        alt="Logo"
                        className="logo"
                        onClick={() => {
                          navigate(`/${siteType}`);
                          localStorage.setItem("siteType", siteType);
                          dispatch(setSiteType({ siteType: siteType }));
                        }}
                      />
                    </h1>
                  </div>
                </div>
                <div className="col-6">
                  <Link
                    to="#"
                    className="text-link"
                    onClick={() => {
                      openContactUsPopup();
                      CounterFloodLight([
                        "DC-12665261/coca-0/cocac01s+unique",
                        "DC-12665261/coca-0/cocac02l+unique",
                      ]);
                    }}
                  >
                    {t.common.need_help}
                  </Link>
                </div>
                <div className="col-6 justify-content-end d-flex">
                  <ul className="list-social d-flex justify-content-center">
                    <li className="list-item">
                      <Link
                        onClick={() => {
                          ClickCDP("Follow_FB");
                          CounterFloodLight([
                            "DC-12665261/coca-0/cocac01y+unique",
                            "DC-12665261/coca-0/cocac02r+unique",
                          ]);
                        }}
                        to={
                          siteType === "tamil"
                            ? "https://www.facebook.com/CokeStudioTamil/"
                            : "https://www.facebook.com/cokestudioind/"
                        }
                        target={"_blank"}
                        className="list-link"
                      >
                        <span className="icon icon-FB-01"></span>
                      </Link>
                    </li>
                    <li className="list-item">
                      <Link
                        to={
                          siteType === "tamil"
                            ? "https://www.instagram.com/cokestudiotamil/"
                            : "https://www.instagram.com/cokestudiobharat/"
                        }
                        target={"_blank"}
                        className="list-link"
                        onClick={() => {
                          ClickCDP("Follow_Insta");
                          CounterFloodLight([
                            "DC-12665261/coca-0/cocac020+unique",
                            "DC-12665261/coca-0/cocac02v+unique",
                          ]);
                        }}
                      >
                        <span className="icon icon-insta-01"></span>
                      </Link>
                    </li>
                    {/* <li className="list-item">
                      <Link
                        to={
                          siteType === 'tamil'
                            ? 'https://twitter.com/CokeStudioTamil'
                            : 'https://x.com/cokestudioind?t=5z5Ey4nhViAaW0Q-I5xNPA&s=09'
                        }
                        className="list-link"
                        target={'_blank'}
                        onClick={() => ClickCDP('Follow_Twitter')}>
                        <span className="icon icon-twitter-01"></span>
                      </Link>
                    </li> */}
                    <li className="list-item">
                      <Link
                        to={
                          siteType === "tamil"
                            ? "https://www.youtube.com/@cokestudiotamil"
                            : "https://www.youtube.com/@cokestudioindia"
                        }
                        className="list-link"
                        target={"_blank"}
                        onClick={() => {
                          ClickCDP("Follow_YT");
                          CounterFloodLight([
                            "DC-12665261/coca-0/cocac02g+unique",
                            "DC-12665261/coca-0/cocac03-+unique",
                          ]);
                        }}
                      >
                        <span className="icon icon-Youtube-01"></span>
                      </Link>
                    </li>
                    {/* <li className="list-item">
                      <Link to={'#'} className="list-link" target={'_blank'} onClick={() => ClickCDP("Follow_WA")}>
                        <span className="icon icon-whatsapp-01"></span>
                      </Link>
                    </li> */}
                  </ul>
                </div>
                <div className="col-3 d-flex justify-content-between">
                  <ul className="pl-0">
                    <li className="py-2">
                      <Link
                        onClick={() =>
                          CounterFloodLight([
                            "DC-12665261/coca-0/cocac01z+unique",
                            "DC-12665261/coca-0/cocac02s+unique",
                          ])
                        }
                        to={addPathSeparators("/faq")}
                        className="link"
                      >
                        {t.common.faq}
                      </Link>
                    </li>
                    {/* <li className="py-2">
                      <Link to={addPathSeparators('/sitemap')} className="link">
                        {t.common.sitemap}
                      </Link>
                    </li> */}
                    <li className="py-2">
                      <Link
                        onClick={() => {
                          CounterFloodLight([
                            "DC-12665261/coca-0/cocac02-+unique",
                            "DC-12665261/coca-0/cocac033+unique",
                          ]);
                        }}
                        to={addPathSeparators("/privacy_policy")}
                        className="link"
                      >
                        {t.common.privacy_policy}
                      </Link>
                    </li>
                  </ul>
                  <ul className="pl-5">
                    <li className="py-2">
                      <Link
                        onClick={() => {
                          CounterFloodLight([
                            "DC-12665261/coca-0/cocac02e+unique",
                            "DC-12665261/coca-0/cocac038+unique",
                          ]);
                        }}
                        to={addPathSeparators("/terms_conditions")}
                        className="link"
                      >
                        {t.common.terms_of_use}
                      </Link>
                    </li>
                    {/* <li className="py-2">
                      <Link
                      onClick={() => {
                CounterFloodLight([
                  'DC-12665261/coca-0/cocac02-+unique',
                  'DC-12665261/coca-0/cocac033+unique',
                ]);
              }}
                        to={addPathSeparators('/privacy_policy')}
                        className="link">
                        {t.common.privacy_policy}
                      </Link>
                    </li> */}
                  </ul>
                </div>
                <div className="col-12">
                  <div className="footer-border border-top pt-7 mt-12">
                    <p className="text-light text-right">
                      &copy; {t.common.footer_subheading}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showsuccessModel.show && (
            <SuccessModel
              show={showsuccessModel.show}
              {...contentType}
              onClose={() =>
                setShowSuccessModel({ show: false, modelType: '' })
              }
            />
          )}
        </footer>
      )}
    </>
  );
}

export default Footer;
